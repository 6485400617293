import { memo, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

import NavItem from './NavItem';

import logo from 'asset/logo/outilitri_white.png';

import './style.css';
import { ArrowBackIos, ArrowForwardIos, Article, Build, Delete, DevicesOther, QueryStats, Recycling, Support } from '@mui/icons-material';
import { usePermissions } from 'features/auth/hooks/usePermissions';

const Navbar = ( { open = false, setOpen, isMobile = false } ) => {
    const { isAdmin } = usePermissions();
    const logo_id = open !== null ? open ? "logo-open" : "logo-close" : null;

    const retractNavBar = () => {
        if(isMobile) return;
        let isOpen = !open;
        localStorage.setItem("openNavbar", isOpen.toString());
        setOpen(!open);
    }

    useEffect(() => {
        if(isMobile) return;
        const isOpen = (!localStorage.getItem("openNavbar") || localStorage.getItem("openNavbar") === "true") ? true : false;
        setOpen(isOpen);
        localStorage.setItem("openNavbar", isOpen.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id={"main-menu"}>
            {
                isMobile ? null : 
                open != null ?
                    open ?
                        <ArrowBackIos className='retract-icon' onClick={retractNavBar} />
                    :
                        <ArrowForwardIos className='retract-icon' onClick={retractNavBar} />
                : null
            }
            <Link to={"/"}>
                <img className="main-logo" id={logo_id} src={logo} alt="Outilitri-logo" title="Logo-Outilitri"/>
            </Link>
            <Grid container spacing={2}>
                {
                    isAdmin() ? 
                        <MenuAdmin open={open} /> 
                    : 
                        <MenuClient open={open} />
                }
            </Grid>
            {
                <div className="version-site-web-container">
                    v 2.6.2
                </div>
            }
        </div>
    );
}

const MenuClient = ({ open }) => {
    return (
        <>
            <NavItem open={open} text="Tableau de bord" link="/dashboard" icon={<Support />} />
            <NavItem open={open} text="Mes poubelles" link="/garbages" icon={<Delete />} />
            <NavItem open={open} text="Mes règles de tri" link="/consignes" icon={<Recycling />} />
            <NavItem open={open} text="Mon matériel" link="/equipments" icon={<DevicesOther />} />
            <NavItem open={open} text="Ressources" link="/ressources" icon={<Article />} />
            {/* <NavItem open={open} text="Mes déchets" link="/wastes" icon={<Cyclone />} /> */}
            {/* <NavItem open={open} text="Statistiques" link="/statistics" icon={<QueryStats />} /> */}
        </>
    )
}

const MenuAdmin = ({ open }) => {
    return (
        <>
            <MenuClient open={open} />
            {/* <NavItem open={open} text="Pilotage" link="/pilotages" icon={<Gamepad />} /> */}
            <NavItem open={open} text="Statistiques" link="/statistics" icon={<QueryStats />} />
            <NavItem open={open} text="Configuration du site" link="/parameters" icon={<Build />} />
        </>
    )
}

export default memo(Navbar);


